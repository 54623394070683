#ordenesCompras {
  @media screen and (max-width: 599px) {
    .top-bg {
      height: 200px;
    }
  }

  .center {
    .layout-wrap {
      @media screen and (max-width: 599px) {
        flex-wrap: nowrap;
      }
    }

    .header {
      height: 136px !important;
      min-height: 136px !important;
      max-height: 136px !important;
      padding: 24px 0;

      @media screen and (max-width: 599px) {
        height: 200px !important;
        max-height: 200px !important;
      }

      .h1 {
        md-icon {
          margin-right: 12px;
        }
        @media screen and (max-width: 599px) {
          font-size: 2rem;
        }
      }

      .order-count {
        margin: 6px 0 0 36px;

        @media screen and (max-width: 599px) {
          margin: 0 0 0 36px;
        }
      }

      .search {
        position: relative;

        .md-button.md-raised.exportExcel[disabled] {
          color : rgba(0, 0, 0, 0.26) !important;
          background-color: rgba(0,0,0,0.12) !important;
          .md-icon{
            color: rgba(0,0,0,0.38) !important;
          }
        }

        .search-icon {
          margin: 0 -24px 0 0;
        }

        .search-input {
          margin: 0 0 0 32px;
          background: none;
          font-size: 16px;
          line-height: 40px;
          height: 40px;
          color: #FFFFFF;
          border-bottom: 2px solid rgba(255, 255, 255, 0.3);
          transition: border-color 300ms ease;

          &:focus {
            border-color: rgba(255, 255, 255, 1);
          }

          &::-webkit-input-placeholder {
            color: rgba(255, 255, 255, 0.5);
          }
        }

        .md-button {
          @media screen and (max-width: 599px) {
            min-width: 25px;
          }

          @media screen and (min-width: 600px) and (max-width: 959px){
            font-weight: 400;
          }
        }
      }

    }

    .add-event-button {
      position: absolute;
      right: 18px;
      top: 135px;
    }

    .print-button, .exportExcel {
      md-progress-circular {
        display: inline;
        margin-right: 24px;
      }
    }

    .sel-event-button {
      right: -37px;
      top: 50px;
      line-height: 30px;
      min-width: 0;
      width: 30px;
      height: 30px;
      padding: 0 0 0 3px;

      md-icon[md-font-icon], i {
        font-size: 1em;
        margin: 0;
      }

      @media screen and (max-width: 599px) {
        right: 3px;
      }
    }

    .content-card {

      .toolbar {
        padding: 8px;
      }

      .content {
        padding: 0;

        .dataTables_wrapper {

          .dataTables_scrollHead {
            min-height: 64px;

            table {

              th {
                padding-top: 20px;
                padding-bottom: 20px;
              }
            }
          }

          .dataTables_scrollBody {

            table {

              .status {
                font-size: 12px;
                white-space: nowrap;
                padding: 2px 5px;
                border-radius: 2px;
              }
            }
          }
        }
      }
    }
  }
}
