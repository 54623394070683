/**
 *  Do not remove this comments bellow. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
@import "../../bower_components/datatables-responsive/css/responsive.dataTables.scss";
// endbower

/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
@import "core/scss/global.scss";
@import "core/directives/ms-card/templates/template-1/template-1.scss";
@import "core/directives/ms-card/templates/template-10/template-10.scss";
@import "core/directives/ms-card/templates/template-3/template-3.scss";
@import "core/directives/ms-card/templates/template-2/template-2.scss";
@import "core/directives/ms-card/templates/template-4/template-4.scss";
@import "core/directives/ms-card/templates/template-5/template-5.scss";
@import "core/directives/ms-card/templates/template-8/template-8.scss";
@import "core/directives/ms-card/templates/template-9/template-9.scss";
@import "core/directives/ms-card/templates/template-6/template-6.scss";
@import "core/directives/ms-card/templates/template-7/template-7.scss";
@import "core/directives/ms-stepper/templates/vertical/vertical.scss";
@import "core/directives/ms-stepper/templates/horizontal/horizontal.scss";
@import "core/theme-options/theme-options.scss";
@import "core/directives/ms-card/ms-card.scss";
@import "core/directives/ms-form-wizard/ms-form-wizard.scss";
@import "core/directives/ms-info-bar/ms-info-bar.scss";
@import "core/directives/ms-masonry/ms-masonry.scss";
@import "core/directives/ms-nav/ms-nav.scss";
@import "core/directives/ms-material-color-picker/ms-material-color-picker.scss";
@import "core/directives/ms-scroll/ms-scroll.scss";
@import "core/directives/ms-responsive-table/ms-responsive-table.scss";
@import "core/directives/ms-shortcuts/ms-shortcuts.scss";
@import "core/directives/ms-splash-screen/ms-splash-screen.scss";
@import "core/directives/ms-navigation/ms-navigation.scss";
@import "core/directives/ms-timeline/ms-timeline.scss";
@import "core/directives/ms-widget/ms-widget.scss";
@import "core/directives/ms-search-bar/ms-search-bar.scss";
@import "core/directives/ms-stepper/ms-stepper.scss";
@import "main/catalogos/views/codigoProducto/dialogs/codigoProducto-form/codigoProducto-form-dialog.scss";
@import "main/catalogos/views/formaspago/dialogs/formaPago-form/formaPago-form-dialog.scss";
@import "main/catalogos/views/tarifaImpuesto/dialogs/tarifaImpuesto-form/tarifaImpuesto-form-dialog.scss";
@import "main/catalogos/views/codigoServicio/dialogs/codigoServicio-form/codigoServicio-form-dialog.scss";
@import "main/catalogos/views/tiposClientes/dialogs/tipoCliente-form/tipoCliente-form-dialog.scss";
@import "main/catalogos/views/tiposArticulos/dialogs/tipoArticulo-form/tipoArticulo-form-dialog.scss";
@import "main/catalogos/views/tiposCambios/dialogs/tipoCambio-form/tipoCambio-form-dialog.scss";
@import "main/catalogos/views/tiposImpuestos/dialogs/tipoImpuesto-form/tipoImpuesto-form-dialog.scss";
@import "main/catalogos/views/tiposMonedas/dialogs/tipoMoneda-form/tipoMoneda-form-dialog.scss";
@import "main/catalogos/views/tiposServicios/dialogs/tipoServicio-form/tipoServicio-form-dialog.scss";
@import "main/catalogos/views/tiposidentificacion/dialogs/form/form-dialog.scss";
@import "main/catalogos/views/tiposGarantias/dialogs/tipoGarantia-form/tipoGarantia-form-dialog.scss";
@import "main/compras_ventas/views/cotizaciones/dialog/detalle-articulo/detalle-articulo.scss";
@import "main/compras_ventas/views/facturas/dialog/detalle-articulo/detalle-articulo.scss";
@import "main/compras_ventas/views/facturas/dialog/detalle-contingencia/detalle-contingencia.scss";
@import "main/compras_ventas/views/facturas/dialog/detalle-referencia/detalle-referencia.scss";
@import "main/compras_ventas/views/facturas/dialog/detalle-servicio/detalle-servicio.scss";
@import "main/compras_ventas/views/facturas/dialog/ver-detalle-articulo/ver-detalle-articulo.scss";
@import "main/compras_ventas/views/facturas/dialog/ver-detalle-servicio/ver-detalle-servicio.scss";
@import "main/compras_ventas/views/facturas/dialog/search-factura-referencia/factura-referencia-search.scss";
@import "main/compras_ventas/views/facturas_electronicas_compras/dialog/detalle-articulo/detalle-articulo.scss";
@import "main/compras_ventas/views/facturas_electronicas_compras/dialog/detalle-servicio/detalle-servicio.scss";
@import "main/compras_ventas/views/facturas_electronicas_compras/dialog/ver-detalle-articulo/ver-detalle-articulo.scss";
@import "main/compras_ventas/views/facturas_electronicas_compras/dialog/ver-detalle-servicio/ver-detalle-servicio.scss";
@import "main/configuraciones/views/configuracionesEmailsEmpresa/dialogs/configuracionEmailEmpresa-form/configuracionEmailEmpresa-form-dialog.scss";
@import "main/configuraciones/views/configuracionesFirmasEmpresa/dialogs/configuracionFirmaEmpresa-form/configuracionFimaEmpresa-form-dialog.scss";
@import "main/configuraciones/views/parametros/dialogs/parametro-form/parametro-form-dialog.scss";
@import "main/configuraciones/views/configuracionesEmailsEntradaEmpresa/dialogs/configuracionEmailEmpresa-form/configuracionEmailEmpresa-form-dialog.scss";
@import "main/mantenimientos/views/contactoClientes/dialogs/contactoCliente-form/contactoCliente-form-dialog.scss";
@import "main/mantenimientos/views/entidadesBancarias/dialogs/entidadBancaria-form/entidadBancaria.scss";
@import "quick-panel/quick-panel.scss";
@import "toolbar/toolbar.scss";
@import "main/dashboard/dashboard.scss";
@import "navigation/layouts/vertical-navigation/navigation.scss";
@import "navigation/layouts/vertical-navigation-fullwidth-toolbar-2/navigation.scss";
@import "navigation/layouts/horizontal-navigation/navigation.scss";
@import "main/auth/lock/lock.scss";
@import "main/auth/login-v2/login-v2.scss";
@import "main/errors/403/error-403.scss";
@import "quick-panel/tabs/activity/activity-tab.scss";
@import "quick-panel/tabs/today/today-tab.scss";
@import "quick-panel/tabs/chat/chat-tab.scss";
@import "toolbar/layouts/content-with-toolbar/toolbar.scss";
@import "toolbar/layouts/horizontal-navigation/toolbar.scss";
@import "toolbar/layouts/vertical-navigation-fullwidth-toolbar-2/toolbar.scss";
@import "main/catalogos/views/codigoProducto/codigosProducto.scss";
@import "main/catalogos/views/formaspago/formasPago.scss";
@import "main/catalogos/views/codigoServicio/codigosServicio.scss";
@import "main/catalogos/views/tarifaImpuesto/tarifasImpuestos.scss";
@import "main/catalogos/views/tiposArticulos/tiposArticulos.scss";
@import "main/catalogos/views/tiposClientes/tiposClientes.scss";
@import "main/catalogos/views/tiposCambios/tiposCambios.scss";
@import "main/catalogos/views/tiposImpuestos/tiposImpuestos.scss";
@import "main/catalogos/views/tiposMonedas/tiposMonedas.scss";
@import "main/catalogos/views/tiposServicios/tiposServicios.scss";
@import "main/catalogos/views/tiposidentificacion/tiposIdentificacion.scss";
@import "main/compras_ventas/views/confirmaciones-comprobantes/confirmaciones-comprobantes.scss";
@import "main/catalogos/views/tiposGarantias/tiposGarantias.scss";
@import "main/compras_ventas/views/cotizaciones/cotizaciones.scss";
@import "main/compras_ventas/views/facturas/facturas.scss";
@import "main/compras_ventas/views/facturas_electronicas_compras/facturas.scss";
@import "main/compras_ventas/views/ordenesCompras/ordenesCompras.scss";
@import "main/configuraciones/views/configuracionesEmailsEmpresa/configuracionesEmailsEmpresa.scss";
@import "main/configuraciones/views/configuracionesFirmasEmpresa/configuracionesFimasEmpresa.scss";
@import "main/configuraciones/views/configuracionesEmailsEntradaEmpresa/configuracionesEmailsEmpresa.scss";
@import "main/configuraciones/views/empresas/empresas.scss";
@import "main/configuraciones/views/parametros/parametros.scss";
@import "main/cuentas/views/abonosCobrar/abonosCobrar.scss";
@import "main/cuentas/views/cancelacionesPagar/cancelacionesPagar.scss";
@import "main/cuentas/views/cuentasPagar/cuentasPagar.scss";
@import "main/inventarios/views/articulos/articulos.scss";
@import "main/inventarios/views/entradasMercaderias/entradasMercaderias.scss";
@import "main/cuentas/views/cuentasCobrar/cuentasCobrar.scss";
@import "main/inventarios/views/salidasMercaderias/salidasMercaderias.scss";
@import "main/inventarios/views/servicios/servicios.scss";
@import "main/reportes/views/articulos/existencias-report.scss";
@import "main/reportes/views/ccPeriodo/cc-periodo.scss";
@import "main/reportes/views/ccCliente/cc-cliente.scss";
@import "main/reportes/views/bitacoras/bitacoras-report.scss";
@import "main/reportes/views/coCliente/co-cliente.scss";
@import "main/reportes/views/cpPeriodo/cp-periodo.scss";
@import "main/reportes/views/coPeriodo/co-periodo.scss";
@import "main/reportes/views/faCliente/fa-cliente.scss";
@import "main/reportes/views/emPeriodo/em-periodo.scss";
@import "main/reportes/views/cpProveedor/cp-proveedor.scss";
@import "main/reportes/views/faPeriodo/fa-periodo.scss";
@import "main/reportes/views/mcPeriodo/mc-periodo.scss";
@import "main/reportes/views/mbPeriodo/mb-periodo.scss";
@import "main/reportes/views/smPeriodo/sm-periodo.scss";
@import "main/reportes/views/ocPeriodo/oc-periodo.scss";
@import "main/reportes/views/ocProveedor/oc-proveedor.scss";
@import "main/mantenimientos/views/bodegas/bodegas.scss";
@import "main/mantenimientos/views/clientes/clientes.scss";
@import "main/mantenimientos/views/contactosProveedores/contactosProveedores.scss";
@import "main/mantenimientos/views/cuentasProveedores/cuentasProveedores.scss";
@import "main/mantenimientos/views/contactoClientes/contactoClientes.scss";
@import "main/mantenimientos/views/cuentasBancarias/cuentasBancarias.scss";
@import "main/mantenimientos/views/entidadesBancarias/entidadesBancarias.scss";
@import "main/mantenimientos/views/proveedores/proveedores.scss";
@import "main/mantenimientos/views/marcas/marcas.scss";
@import "main/movimientos/views/dateFilter/dateFilter.scss";
@import "main/movimientos/views/movimientosCajas/movimientosCajas.scss";
@import "main/movimientos/views/movimientosBancos/movimientosBancos.scss";
@import "main/seguridad/views/perfiles/perfiles.scss";
@import "main/seguridad/views/usuarios/usuarios.scss";
@import "main/catalogos/views/codigoProducto/search/codigoProducto-search.scss";
@import "main/catalogos/views/codigoServicio/search/codigoServicio-search.scss";
@import "main/catalogos/views/tarifaImpuesto/search/tarifaImpuesto-search.scss";
@import "main/compras_ventas/views/confirmaciones-comprobantes/enviar-automaticas/confirmacion-comprobante-enviar-auto.scss";
@import "main/compras_ventas/views/confirmaciones-comprobantes/create/confirmacion-comprobante-create.scss";
@import "main/compras_ventas/views/cotizaciones/create/cotizacion-create.scss";
@import "main/compras_ventas/views/cotizaciones/edit/cotizacion-edit.scss";
@import "main/compras_ventas/views/cotizaciones/view/cotizacion-view.scss";
@import "main/compras_ventas/views/facturas/create/factura-create.scss";
@import "main/compras_ventas/views/facturas/edit/factura-edit.scss";
@import "main/compras_ventas/views/facturas/view/factura-view.scss";
@import "main/compras_ventas/views/facturas_electronicas_compras/create/factura-create.scss";
@import "main/compras_ventas/views/facturas/multiple/factura-multiple.scss";
@import "main/compras_ventas/views/facturas_electronicas_compras/view/factura-view.scss";
@import "main/compras_ventas/views/ordenesCompras/create/ordenCompra-create.scss";
@import "main/compras_ventas/views/ordenesCompras/edit/ordenCompra-edit.scss";
@import "main/compras_ventas/views/facturas_electronicas_compras/edit/factura-edit.scss";
@import "main/compras_ventas/views/ordenesCompras/facturaProveedor/facturaProveedor.scss";
@import "main/catalogos/views/tiposImpuestos/search/tipoImpuesto-search.scss";
@import "main/compras_ventas/views/ordenesCompras/view/ordenCompra-view.scss";
@import "main/configuraciones/views/empresas/create/empresa-create.scss";
@import "main/configuraciones/views/empresas/edit/empresa-edit.scss";
@import "main/cuentas/views/abonosCobrar/form/abonoCobrar.scss";
@import "main/cuentas/views/cuentasPagar/create/cuentaPagar-create.scss";
@import "main/cuentas/views/cuentasPagar/edit/cuentaPagar-edit.scss";
@import "main/cuentas/views/cancelacionesPagar/form/cancelacionPagar.scss";
@import "main/inventarios/views/articulos/create/articulo-create.scss";
@import "main/inventarios/views/articulos/edit/articulo-edit.scss";
@import "main/inventarios/views/articulos/price/priceArticulo-dialog.scss";
@import "main/inventarios/views/articulos/transactions/transactions.scss";
@import "main/inventarios/views/articulos/quantity/quantity-dialog.scss";
@import "main/inventarios/views/articulos/search/searchArticulo-dialog.scss";
@import "main/inventarios/views/entradasMercaderias/create/entradaMercaderia-create.scss";
@import "main/inventarios/views/entradasMercaderias/view/entradaMercaderia-view.scss";
@import "main/inventarios/views/entradasMercaderias/edit/entradaMercaderia-edit.scss";
@import "main/cuentas/views/cuentasCobrar/create/cuentaCobrar-create.scss";
@import "main/cuentas/views/cuentasCobrar/edit/cuentaCobrar-edit.scss";
@import "main/inventarios/views/salidasMercaderias/create/salidaMercaderia-create.scss";
@import "main/inventarios/views/salidasMercaderias/view/salidaMercaderia-view.scss";
@import "main/inventarios/views/salidasMercaderias/edit/salidaMercaderia-edit.scss";
@import "main/inventarios/views/servicios/edit/servicio-edit.scss";
@import "main/inventarios/views/servicios/search/searchServicio-dialog.scss";
@import "main/inventarios/views/servicios/price/priceServicio-dialog.scss";
@import "main/inventarios/views/servicios/create/servicio-create.scss";
@import "main/mantenimientos/views/clientes/edit/cliente-edit.scss";
@import "main/mantenimientos/views/clientes/form/cliente-form.scss";
@import "main/mantenimientos/views/clientes/search/cliente-search.scss";
@import "main/mantenimientos/views/contactosProveedores/search/contactoProveedor-search.scss";
@import "main/mantenimientos/views/contactoClientes/search/contactoCliente-search.scss";
@import "main/mantenimientos/views/proveedores/edit/proveedor-edit.scss";
@import "main/mantenimientos/views/proveedores/create/proveedor-create.scss";
@import "main/mantenimientos/views/proveedores/search/proveedor-search.scss";
@import "main/movimientos/views/movimientosCajas/form/movimientoCaja.scss";
@import "main/movimientos/views/movimientosBancos/form/movimientoBanco.scss";
@import "main/seguridad/views/perfiles/create/perfil-create.scss";
@import "main/seguridad/views/usuarios/create/usuario-create.scss";
@import "main/seguridad/views/perfiles/edit/perfil-edit.scss";
@import "main/seguridad/views/usuarios/edit/usuario-edit.scss";
// endinjector

md-input-container.editable-controls {
  padding: 0;
}

md-input-container.editable-controls [ng-message] {
  position: static;
}

/*Solucion de css*/
md-input-container.editable-controls {
  padding: 1px 1px 1px 10px;
  margin: 1px ;
}

.editable-input.md-input {
  font-size: 1em;
  font-weight: 400;
  max-width: 90%;
  margin: 1px ;
}

.editable-wrap.editable-text {
  margin: 1px ;
}

md-input-container.editable-controls [ng-message] {
  position: static !important;
}

.dataTables_length select {
  border: 1px solid #dddbbb;
}

form {
  .date-picker {
    padding-right: 18px;
    height: 58px !important;
    margin-top: 8px !important;

    label {
      left: 33px !important;
      right: 0 !important;
      width: calc(100% - 33px) !important;
    }

    md-datepicker {
      display: inline-block;
      width: 100%;
      height: 0;

      .md-datepicker-button {
        margin-top: -20px !important;
        margin-left: -33px;
      }

      .md-datepicker-input-container {
        width: 100%;
        top: -14px;
        right: 17px;

        .md-datepicker-input {
          max-width: 100% !important;
          min-width: 100% !important;
        }

        .md-datepicker-expand-triangle {
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 4px solid;
        }
      }
    }

    .md-input-messages-animation {
      .md-input-message-animation {
        opacity: 1;
        margin-top: 6px !important;
        margin-left: 34px !important;
      }
    }
  }
}
