#factura-view {

  > .header {
    height: 120px;
    min-height: 120px;
    max-height: 120px;

    .goto-orders-button {
      margin-right: 16px;
    }

    .subtitle {
      margin: 6px 0 0 0;
    }
  }

  md-switch.md-default-theme[disabled].md-checked .md-thumb, md-switch[disabled].md-checked .md-thumb {
    background-color: rgb(3,155,229);
  }

  md-switch.md-default-theme[disabled].md-checked .md-bar, md-switch[disabled].md-checked .md-bar{
    background-color: rgba(3,155,229,0.5);
  }

  .form-container {
    margin: 24px;
  }

  .order-detail-form-container {
    padding: 24px;
    margin-bottom: 24px;

    table.xeditable tbody tr td {
      padding: 5px;
    }

    table thead tr th {
      text-align: center;
    }

    td.text-center {
      text-align: center;
    }

    td.text-right {
      text-align: right;
    }
  }

  md-tabs-wrapper {
    background-color: #ffffff;
  }

  form {
    .select-container {
      margin-bottom: 30px;
    }

    md-input-container {
      margin-top: 2.5px;
      margin-bottom: 2.5px;
    }

    .input-row {

      md-list {
        padding: 0;
      }

      @media screen and (min-width: 960px) {
        md-datepicker {
          margin-right: 24px;
        }
        .md-left {
          margin-right: 24px;
          max-width: 35%;
        }
        .md-left-image {
          max-width: 10%;
        }
        .md-left-equals {
          margin-right: 24px;
        }
      }
    }

    .select-container {
      margin-bottom: 30px;
    }

    .select-container {
      margin-bottom: 30px;
    }
  }
}
