#movimientoBanco-form {
  form {
    .select-container {
      margin-bottom: 30px;
    }

    .input-row {

      @media screen and (min-width: 960px) {
        md-datepicker {
          margin-right: 24px;
        }
        .md-left {
          margin-right: 24px;
          max-width: 35%;
        }
        .md-left-image {
          max-width: 10%;
        }
        .md-left-equals {
          margin-right: 24px;
        }
      }
    }
  }
}
