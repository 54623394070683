[md-theme="default"] .md-accent-bg {
  background-color: rgb(3,155,229);
  color: rgba(255,255,255,1);
}

[md-theme="pinkTheme"] .md-accent-bg {
  background-color: rgb(236,64,122);
  color: rgba(255,255,255,1);
}

[md-theme="tealTheme"] .md-accent-bg {
  background-color: rgb(0,150,136);
  color: rgba(255,255,255,1);
}

#dashboard-ecommerce {

  .center {

    .header {
      height: 160px;
      min-height: 160px;
      max-height: 160px;
      padding-bottom: 0;
      background-image: url(/assets/images/backgrounds/header-bg.png);
      background-size: cover;
    }

    .content {
      background: none;
      padding: 0;

      .angular-google-map-container {
        height: 500px;
      }

      .img-dashboard{
        display: block;
        margin: 0 auto;
      }

      .img-container{
        padding-top: 17%;
      }

      .mount{
        font-size: 50px;
      }

      .mount-top{
        font-size: 25px;
      }

      @media (max-width: 767px) {
        .img-container{
          padding-top: 40%;
        }
      }

      @media (max-width: 991px) {
        .img-container {
          padding-top: 40%;
        }
      }

      @media (max-width: 1199px) {
        .img-container {
          padding-top: 15%;
        }
      }
    }
  }
}