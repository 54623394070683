#facturaProveedor-form {
  .file-zoom:hover {
    z-index: 100;
    -moz-transform: scale(8, 8) translate(25px, -17.5px);
    -ms-transform: scale(8, 8) translate(25px, -17.5px);
    -o-transform: scale(8, 8) translate(25px, -17.5px);
    -webkit-transform: scale(8, 8) translate(20px, -17.5px);
    transform: scale(8, 8) translate(25px, -17.5px);
    cursor: pointer;
  }

  form {
    .select-container {
      margin-bottom: 30px;
    }

    .input-row {

      @media screen and (min-width: 960px) {
        md-datepicker {
          margin-right: 24px;
        }
        .md-left {
          margin-right: 24px;
          max-width: 35%;
        }
        .md-left-image {
          max-width: 10%;
        }
        .md-left-equals {
          margin-right: 24px;
        }
      }
    }
  }
}
