md-dialog.detalle-servicio {

  &.event-form-dialog {
    width: 80%;
    max-width: 80%;

    md-dialog-content {
      display: block;
      position: relative;
    }

    form {
      overflow: hidden;

      md-toolbar {
        .title {
          font-size: 17px;
        }
      }

      .notifications {
        padding: 16px 0;

        > .md-button {
          margin: 0;
        }
      }

      .select-container {
        margin-bottom: 30px;
      }

      md-input-container {
        margin-top: 2.5px;
        margin-bottom: 2.5px;
      }

      .input-row {
        md-list {
          padding: 0;
        }

        @media screen and (min-width: 960px) {
          md-datepicker {
            margin-right: 24px;
          }
          .md-left {
            margin-right: 24px;
            max-width: 35%;
          }
          .md-left-image {
            max-width: 10%;
          }
          .md-left-equals {
            margin-right: 24px;
          }
        }
      }

      .date-picker {
        padding-right : 20px !important;
        height        : 58px !important;
        margin-top    : -1px !important;

        label {
          left  : 33px !important;
          right : 0 !important;
          width : calc(100% - 33px) !important;
        }

        md-datepicker {
          display     : inline-block;
          width       : 100%;
          height      : 0;

          .md-datepicker-button {
            margin-top  : -25px !important;
            margin-left : -33px;
          }

          .md-datepicker-input-container {
            width : 100%;
            top   : -14px;
            right : 17px;

            .md-datepicker-input {
              max-width : 100% !important;
              min-width : 100% !important;
            }

            .md-datepicker-expand-triangle {
              border-left  : 4px solid transparent;
              border-right : 4px solid transparent;
              border-top   : 4px solid;
            }
          }
        }

        .md-input-messages-animation {
          .md-input-message-animation {
            opacity     : 1;
            margin-top  : 6px !important;
            margin-left : 34px !important;
          }
        }
      }
    }

    md-dialog-actions {
      position: relative;
      overflow-y: hidden;
      overflow-x: auto;
      justify-content: space-between;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      background-color: rgba(0, 0, 0, 0.03);
    }
  }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {

  md-dialog.search-articulo {

    &.event-form-dialog {
      width: 80%;
    }
  }
}

@media screen and (max-width: $layout-breakpoint-xs) {

  md-dialog.search-articulo {

    &.event-form-dialog {
      width: 90%;
    }
  }
}
