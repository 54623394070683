#articulo-crate {

  > .header {
    height: 120px;
    min-height: 120px;
    max-height: 120px;

    .goto-orders-button {
      margin-right: 16px;
    }

    .subtitle {
      margin: 6px 0 0 0;
    }
  }

  .form-container {
    margin: 24px;
  }

  md-tabs-wrapper {
    background-color: #ffffff;
  }

  .input-addon .md-icon-button {
    margin-top: 5px;
  }

  form {
    .input-row {

      @media screen and (min-width: 960px) {
        md-datepicker {
          margin-right: 24px;
        }
        .md-left {
          margin-right: 24px;
          max-width: 35%;
        }
        .md-left-image {
          max-width: 10%;
        }
        .md-left-equals {
          margin-right: 24px;
        }
      }
    }

    .select-container {
      margin-bottom: 30px;
    }
  }
}
