md-dialog.cliente-search {

  &.event-form-dialog {
    width: 80%;
    max-width: 80%;

    table.simple tbody tr td {
      padding: 5px;
    }

    table.simple thead tr th {
      min-width: 50px;
    }

    table {
      th.check, td.check {
        min-width: 15px;
        padding-left: 0;
        text-align: center;
      }

      md-progress-circular {
        display: inline-flex;
        margin-right: 24px;
      }
    }

    table.simple {
      font-size: 1.3rem;
    }

    table th {
      text-align: center;
    }

    td.center {
      text-align: center;
    }

    md-dialog-content {
      display: block;
      position: relative;
    }

    form {
      overflow: hidden;

      md-toolbar {

        .title {
          font-size: 17px;
        }
      }

      .date-row {

        md-datepicker {
          margin-right: 24px;
        }
      }

      .notifications {
        padding: 16px 0;

        > .md-button {
          margin: 0;
        }
      }
    }

    md-dialog-actions {
      position: relative;
      overflow-y: hidden;
      overflow-x: auto;
      justify-content: space-between;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      background-color: rgba(0, 0, 0, 0.03);
    }
  }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {

  md-dialog.search-articulo {

    &.event-form-dialog {
      width: 80%;
    }
  }
}

@media screen and (max-width: $layout-breakpoint-xs) {

  md-dialog.search-articulo {

    &.event-form-dialog {
      width: 90%;
    }
  }
}