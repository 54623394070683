#cuentaPagar-edit {
  > .header {
    height: 120px;
    min-height: 120px;
    max-height: 120px;

    .goto-orders-button {
      margin-right: 16px;
    }

    .subtitle {
      margin: 6px 0 0 0;
    }
  }

  .form-container {
    margin: 24px;
  }

  .input-addon .md-icon-button {
    margin-top: 5px;
  }

  .order-detail-form-container {
    padding: 24px;
    margin-bottom: 24px;

    table.xeditable tbody tr td {
      padding: 0;
    }

    table.simple tbody tr td.no-border {
      border-bottom: none;
    }

    table thead tr th {
      text-align: center;
    }

    td.text-center {
      text-align: center;
    }

    td.text-right {
      text-align: right;
    }
  }

  md-tabs-wrapper {
    background-color: #ffffff;
  }

  form {
    .select-container {
      margin-bottom: 30px;
    }

    md-input-container {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .input-row {

      @media screen and (min-width: 960px) {
        md-datepicker {
          margin-right: 24px;
        }
        .md-left {
          margin-right: 24px;
          max-width: 35%;
        }
        .md-left-image {
          max-width: 10%;
        }
        .md-left-equals {
          margin-right: 24px;
        }
      }
    }

    .select-container {
      margin-bottom: 30px;
    }
  }
}

